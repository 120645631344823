$(document).ready(function() {

	// Modal

	var modal       = new bootstrap.Modal(document.getElementById('baseModal'), {
		keyboard: false,
		backdrop: 'static',
		focus: true
	});
	var modaltitle  = $('#modal-title');
	var modaltext   = $('#modal-body');



	function submitAJAXForm( _this ) {

		event.preventDefault();

		modaltitle.text('');
		modaltext.text('');

		var spinner = $('#loader');

		var formObj     = _this.parents( 'form' ),
			actionURL   = formObj.find( '[name="action"]' ).val(), //cambiato
			redirectVal = formObj.find( '[name="redirectval"]' ).val();

		if( actionURL != '' && actionURL != undefined ) {

			spinner.show();

			$.ajax({
				type	    : 'POST',
				url		    : actionURL,
				data	    : formObj.serialize(),
				dataType    : 'json',
				cache       : false,
				contentType : false,
				processData : false,
				success: function ( result ) {

					spinner.hide();
					formObj.find( 'input[name=action],input[name=g-recaptcha-response],input[name=invoice-required]' ).remove();
					// formObj.find( 'input[name=rememberCheckbox]' ).remove();

					if ( typeof ( result ) !== 'undefined' && result !== null ) {
						if (typeof result === 'string' || result instanceof String){
							result = $.parseJSON( result );
						}

						formObj.find( 'input[type=text],input[type=email],input[type=tel],input[type=password],textarea' ).each( function () {
							$( this ).val('');
							$( this ).removeClass( 'error' );
							// Anche label? Manca select
						});
						formObj.find( '.g-recaptcha' ).removeClass( 'error' );
						formObj.find( 'input[type=checkbox],input[type=radio]' ).prop( 'checked', false );
						if( formObj.find( '.g-recaptcha' ).length > 0 ) {
							grecaptcha.reset();
						}


						if(result.status || (result.success && result.success != 'error')){
							window.dataLayer = window.dataLayer || []
							dataLayer.push({
								'event': 'formSubmission',
								'formType': formObj.find( '[name="formname"]' ).val(),
								'formPosition': formObj.find( 'input[name="formposition"]' ).val()
							});

							if(result.status || (result.success && result.success != 'false')) {
								modaltitle.html(result.title).text();
								modaltext.html(result.message).text();
								modal.show();
								if(typeof result.redirectVal !== 'undefined') {
									$("#baseModal").on('hidden.bs.modal', function () {
										window.location.href = result.redirectVal;
									});
								}
							}else{
								modaltitle.html(result.title).text();
								modaltext.html(result.message).text();
								modal.show();
							}
						}else{
							modaltitle.html(result.title).text();
							modaltext.html(result.message).text();
							modal.show();
						}
					}
				}
			});
		}
	}


	$(document).on('click', '.submit', function (event) {

		event.preventDefault();

		var _this = $(this);
		var error = false,
			captchaFlag = false,
			formObj = _this.parents('form'),
			emailFormat = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
			telFormat = /[0-9 -()+]{8,}?$/,
			actionURL = formObj.attr('action'),
			grecaptchav3 = _this.attr('data-sitekey') || '';

		formObj.find('input[required]').removeClass('error');
		formObj.find('.help-block').text('');

		formObj.find('input[required], textarea[required]').each(function () { //======>.required
			var __this      = $(this),
				fieldVal    = __this.val(),
				fieldValMex = __this.attr('data-validationmessage');
			if (fieldVal == '' || fieldVal == undefined) {
				error = true;
				__this.addClass('error');
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'checkbox' && !__this.prop('checked')) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'email' && !emailFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no email!');
			} else if (__this.attr('type') == 'tel' && !telFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no tel!');
			}
		});

		// Google reCaptcha Verify
		if (typeof (grecaptcha) !== 'undefined' && grecaptcha !== null) {
			if (formObj.find('.g-recaptcha').length > 0) { // For Version 2
				var gResponse = grecaptcha.getResponse();
				if (!(gResponse.length)) {
					error = true;
					formObj.find('.g-recaptcha').addClass('error');
				}
			} else if (grecaptchav3 != '' && grecaptchav3 != undefined) { // For Version 3
				captchaFlag = true;
				formObj.find('input[name=action],input[name=g-recaptcha-response]').remove();
				grecaptcha.ready(function () {
					grecaptcha.execute(grecaptchav3, {action: 'form'}).then(function (token) {
						formObj.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
						formObj.prepend('<input type="hidden" name="action" value="form">');

						if (!error) {
							submitAJAXForm(_this);
						}
					});
				});
			}
		}
	});

});





const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {


	const toggler       = document.querySelector('.navbar-toggler');
	let navbarHeight            = $('#header').outerHeight(true);
	let delta           = 5;
	let lastScrollTop   = 0;
	let togglerOpen     = false;
	let didScroll;


	function getWindowWidth() {
		return $( window ).width();
	}

	function togglerState() {
		togglerOpen = !togglerOpen;
	}

	function hasScrolled() {

		if(getWindowWidth() < 991) {

			let scrollTop = $(this).scrollTop();

			// Delta min scroll
			if (Math.abs(lastScrollTop - scrollTop) <= delta) {
				return;
			}

			// If top remove scrolldown
			if (scrollTop <= 0) {
				$('body').removeClass('scroll-down')
				return;
			}

			if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
				// Scroll Down
				if (!togglerOpen) {
					$('[data-bs-toggle="dropdown"]').dropdown('hide').blur();
					$('body').removeClass('scroll-up').addClass('scroll-down');
				}
			} else {
				// Scroll Up
				if (!togglerOpen) {
					if (scrollTop + $(window).height() < $(document).height()) {
						$('body').removeClass('scroll-down').addClass('scroll-up');
					}
				}
			}

			lastScrollTop = scrollTop;

		}
	}

	if (toggler) {
		toggler.addEventListener('click', togglerState);
	}



	$(window).scroll(function(){
		if (!togglerOpen) {
			didScroll = true;
		}
	});

	$(window).on('resize', function(){
		$('body').removeClass('scroll-down').addClass('scroll-up');
	});

	// Toggle scroll function state for collapse and anchor links
	$('#header [data-bs-toggle="collapse"]').click(function() {
		togglerOpen = true;
	});
	$('.navbar-collapse').on('hidden.bs.collapse', function () {
		togglerOpen = false;
	});
	$('.navbar-collapse a[href^="#"]:not([href="#"])').click(function () {
		togglerOpen = true;
	});


	// Scroll func performance
	setInterval(function() {
		toggleBacktotop();
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);




	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			let headerOffset = $('#header').outerHeight(true);
			let offsetPosition = elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});

			// Wait scroll function to resume scroll func
			setTimeout(function(){
				togglerOpen = false;
			}, 1000);

		};
	});




	// BACK TO TOP

	const topButton = document.getElementById('top');

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function toggleBacktotop() {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			topButton.style.display = 'block';
		} else {
			topButton.style.display = 'none';
		}
	}

	if(topButton) {
		topButton.addEventListener('click', backToTop);
	}




	// Offcanvas menu nav

	const offcanvasNav = document.getElementById('offcanvasNav');
	const navbarTogglerIcon = document.getElementById('navbarTogglerIcon');
	offcanvasNav.addEventListener('show.bs.offcanvas', event => {
		navbarTogglerIcon.classList.add('closehamb');
	});
	offcanvasNav.addEventListener('hide.bs.offcanvas', event => {
		navbarTogglerIcon.classList.remove('closehamb');
	});




	// Clone desktop sidebar filters to mobile aside offcanvas and vice versa

	const mediaQuery = '(max-width: 991.98px)';
	const mediaQueryList = window.matchMedia(mediaQuery);
	let offcanvasFilters = $('#offcanvasFilters');
	let sidebarListing = $('#listingSidebarContent');

	if (offcanvasFilters) {

		if (mediaQueryList.matches) {

			offcanvasFilters.append(sidebarListing.children().clone());
			sidebarListing.empty();

		}

		mediaQueryList.addEventListener('change', event => {

			if (event.matches) {

				offcanvasFilters.append(sidebarListing.children().clone());
				sidebarListing.empty();

			} else {

				sidebarListing.append(offcanvasFilters.children().clone());
				offcanvasFilters.empty();
				let ocbs = bootstrap.Offcanvas.getInstance(offcanvasFilters[0]);
				if(ocbs) {
					ocbs.hide();
				}

			}

		})

	}



	// PRINT BUTTON

	const printButton = document.getElementById('printButton');

	function printPage() {
		window.print();
	}

	if(printButton) {
		printButton.addEventListener('click', printPage);
	}



	// SHARE BUTTON

	const shareButton = document.getElementById('shareButton');

	if(shareButton) {

		shareButton.addEventListener('click', function () {

			let shareModal = new bootstrap.Modal(document.getElementById('shareModal'));
			shareModal.show();

		});

	}



	// EVENT REGISTER BUTTON

	const eventRegButton = document.getElementById('eventRegButton');

	if(eventRegButton) {

		eventRegButton.addEventListener('click', function () {

			let EventRegModal = new bootstrap.Modal(document.getElementById('EventRegModal'));
			EventRegModal.show();

		});

	}




	// SWIPER CAROUSELS

	const swiperHomePartners = new Swiper('#swiperHomePartners', {

		loop: true,
		// rewind: true,
		slidesPerView: 1,
		spaceBetween: 20,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: true,
		breakpoints: {
			576: {
				slidesPerView: 2
			},
			991: {
				slidesPerView: 3
			},
			1200: {
				slidesPerView: 4
			},
			1600: {
				slidesPerView: 5
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});

	const swiperAboutTestimonials = new Swiper('#swiperAboutTestimonials', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		// autoplay: true,
		pagination: {
			el: ".swiper-pagination",
			dynamicBullets: true,
			clickable: true
		}

	});

	const swiperPartnerSingleServiceTestimonials = new Swiper('#swiperPartnerSingleServiceTestimonials', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		// autoplay: true,
		pagination: {
			el: ".swiper-pagination",
			dynamicBullets: true,
			clickable: true
		}

	});

	const swipernewsHomeCategories = new Swiper('#swipernewsHomeCategories', {

		loop: true,
		// rewind: true,
		slidesPerView: 1,
		spaceBetween: 10,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			1200: {
				slidesPerView: 4
			},
			991: {
				slidesPerView: 3
			},
			576: {
				slidesPerView: 2
			}
		},
		pagination: {
			el: ".swiper-pagination",
			dynamicBullets: true,
			clickable: true
		}
	});

	const swiperEventsHomeCategories = new Swiper('#swiperEventsHomeCategories', {

		loop: true,
		// rewind: true,
		slidesPerView: 1,
		spaceBetween: 10,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			991: {
				slidesPerView: 2
			}
		},
		pagination: {
			el: ".swiper-pagination",
			dynamicBullets: true,
			clickable: true
		}
	});


});